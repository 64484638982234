import BasicComponents from "../components/basic-components";
import {fillData} from "./currencies/table-currencies";
import createCurrencyChart from "../chart";
import {fillCurrencyData} from "../components/main-currencies";
import BudgetManager from "./budget/budget-manager";
import BugetSumary from "./budget/buget-sumary";
import {createCurrentGoldPrice} from "../components/current-gold";


export const createHome = async () => {
    const home = new BasicComponents()

    home.createH1("Centrum zarządania", "mainArea")
    home.addIconToHeading("fas fa-home color-2")

    const balance = localStorage.getItem("balance")
    if( balance <= 0 ){
        home.createP(`Kwota w twoim budżecie wynosi ${balance} dodaj przechody aby móc zainwetować. To powiadomienie zniknie po okresleniu bużetu.`, "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")
    }

    home.createH2("Cena złota", "mainArea", "text")
    await createCurrentGoldPrice().catch((err) => console.log(err));

    home.createH2("Najważniejsze waluty", "mainArea", "text")
    home.createActualCurrencyDataContainer()


    home.createH2("Statystyki", "mainArea", "text")
    home.createDoubleContainer("chart")
    home.createCardChart("chart-left", "Waluty - ostatnie 250 notowań", "myAreaChart", "bar-chart")
    home.createCardChart("chart-right", "Waluty - Ostatnie 90 dni", "myBarChart", "bar-chart")
   // home.createTable("mainArea", "ex")
    createCurrencyChart(250, "myAreaChart");
    createCurrencyChart(90, "myBarChart");
    fillCurrencyData().catch(error => console.log(error));
    // fillData().catch(error => console.log(error));

    const sumary = new BugetSumary()
    sumary.createSumary()

    const budget = new BudgetManager()
    budget.countItems("budget-incomes")
    budget.countItems("budget-outgoings")
    budget.countBalance()
}





