export default class CurrCalculatorService{

    plnToForeign(value, currecy) {
        return (value / currecy).toFixed(2)
    }

    foreignToPln(value, currecy) {
        return (value * currecy).toFixed(2)
    }

}