import BasicComponents from "../../components/basic-components";
import {fillCurrencyData} from "../../components/main-currencies";
import {fillData} from "./table-currencies";
import createCurrencyChart from "../../chart";


export const initCurrencies = () => {
    const currency = new BasicComponents()
    currency.createH1("Waluty", "mainArea")
    currency.addIconToHeading("fa-solid fa-dollar-sign color-2 ")
    currency.createP(`Sprawdz aktualne kursy walut.`, "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")


    currency.createActualCurrencyDataContainer()
    currency.createDoubleContainer("chart")
    currency.createCardChart("chart-left", "Waluty - Ostatnie 250 dni", "myAreaChart", "bar-chart")
    currency.createCardChart("chart-right", "Waluty - Ostatnie 90 dni", "myBarChart", "bar-chart")
    // home.createTable("mainArea", "ex")
    createCurrencyChart(250, "myAreaChart");
    createCurrencyChart(90, "myBarChart");
    fillCurrencyData().catch(error => console.log(error));
    // fillData().catch(error => console.log(error));

    currency.createTable("mainArea", "ex")
    fillData().catch(error => console.log(error));
}

