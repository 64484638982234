import BasicComponents from "../../components/basic-components";
import Form from "../../components/form";
import GrossNetService from "../../../services/gross-net-service";
import {getElement} from "bootstrap/js/src/util";


export const initBruttoNetto = () => {
    const grossNet = new BasicComponents()
    const formBN = new Form()

    grossNet.createH1("Kalkulator VAT", "mainArea")
    grossNet.addIconToHeading("fas fa-calculator color-3")

    grossNet.createDiv("mainGrossNet", "row g-3")

    grossNet.createAndAppendDiv("bruttoNetto", "col-md-6", "mainGrossNet")
    grossNet.createAndAppendDiv("results-vat", "col-md-6", "mainGrossNet")

    grossNet.createAndAppendDiv("bN", "shadow p-4 rounded mt-4 mb-4 col", "bruttoNetto")
    grossNet.createAndAppendDiv("re", "shadow p-4 rounded mt-4 mb-4 col bg-color-2 text-light", "results-vat")

    formBN.createForm("formBN", "bN")
    grossNet.createH2("Wypełnij poniższy formularz", "formBN", "mt-0 pt-0 color-1")
    formBN.createLabel("Wpisz kwotę która chcesz obliczyć", "vat-label", "formBN", "form-gross-input", "form-label mt-4")
    formBN.createNumberInput("form-amount-input", "form-control mb-4", "formBN")
    document.getElementById("form-amount-input").setAttribute('required', true);
    grossNet.createP("Wybierz sposób obliczenia", "text m-0", "formBN")
    formBN.createRadioButton("gross-to-net", "radio", "brutto na netto", "brutto na netto", "formBN")
    formBN.createRadioButton("net-to-gross", "radio", "netto na brutto", "netto na brutto", "formBN")
    document.getElementById("gross-to-net").checked = true
    formBN.createLabel("Wybierz procent vatu do przeliczenia", "vat-label", "formBN", "vatSelect", "form-label mt-4")
    formBN.createSelect("vatSelect", " form-control mb-4", "formBN", [23, 22, 8, 7, 5, 3])
    formBN.createButton("form-BN-submit", "button btn btn-secondary w-100 text-light", "Zatwierdź", "submit", "formBN")

    grossNet.createPWithId("Wybrana opcja: ", "check-options", "mb-4 p-3 shadow rounded text-light bg-color-3", "re")
    grossNet.createSpan(" ", "color-1", "output", "check-options")
    grossNet.createPWithId("Kwota do obliczenia: ", "amout-to-count", "mb-4 p-3 shadow rounded bg-color-3", "re")
    grossNet.createSpan(" ", "color-1", "display-amount", "amout-to-count")
    grossNet.createPWithId("Wybrany procent: ", "vat-percent", "mb-4 p-3 shadow rounded bg-color-3", "re")
    grossNet.createSpan(" ", "color-1", "display-vat-percent", "vat-percent")
    grossNet.createPWithId("Podatek VAT: ", "value-wrap-VAT", "mb-4 p-3 shadow rounded bg-color-5 color-1", "re")
    grossNet.createSpan(" ", "color-1", "value-VAT", "value-wrap-VAT")
    grossNet.createPWithId("Obliczona kwota: ", "main-value-wrap", "mb-0 p-3 shadow rounded bg-color-5 color-1", "re")
    grossNet.createSpan(" ", "color-1", "main-value", "main-value-wrap")

    const form = document.getElementById("formBN")
    const amount = document.getElementById("form-amount-input")
    const percent = document.getElementById("vatSelect")

    const gn = new GrossNetService()

    form.addEventListener("submit", (e) => {
        e.preventDefault()

        document.getElementById("display-amount").textContent = amount.value
        document.getElementById("display-vat-percent").textContent = percent.value

        const option = formBN.getRadioValue()
        const vatDisplay = document.getElementById("value-VAT")
        const mainValue = document.getElementById("main-value")

        if (option === "netto na brutto") {
            vatDisplay.textContent = gn.countVatNetToGross(amount.value, percent.value)
            mainValue.textContent = gn.netToGross(amount.value, percent.value)
        } else {
            vatDisplay.textContent = gn.countVatGrossToNet(amount.value, percent.value)
            mainValue.textContent = gn.grossToNet(amount.value, percent.value)
        }
    })

    grossNet.createP("Kalkulator VAT (brutto-netto) to narzędzie, które umożliwia przeliczenie kwoty brutto na netto oraz kwoty netto na brutto, z uwzględnieniem wybranej stawki VAT. Dodatkowo, oblicza on wartość samego podatku VAT, co pozwala na szybkie i precyzyjne określenie wszystkich składników ceny. Użytkownik musi jedynie wybrać odpowiednią metodę przeliczenia – czy chce obliczyć kwotę netto na podstawie brutto, czy odwrotnie – a kalkulator automatycznie wykonuje resztę obliczeń. ", "alert m-0 bg-color-3 color-5", "mainArea")

    const a = document.createElement("a")

    a.href = "https://www.ey.com/pl_pl/tax/podatki-posrednie-vat-akcyza-clo/podatek-vat-w-polsce-co-warto-wiedziec-o-podatku-od-towarow-i-uslug"
    a.textContent = "Więcej o poadtku Vat"
    a.className = "color-1"

    document.querySelector("p.alert").appendChild(a)
}






