import BasicComponents from "../../components/basic-components";
import Form from "../../components/form";
import BudgetManager from "./budget-manager";
import BugetSumary from "./buget-sumary";
import {createBudgetForms} from "./budget-forms";

export const initBudget = () => {
    const budget = new BasicComponents()
    const formBudget = new Form()

    budget.createH1("Budżet", "mainArea")
    budget.addIconToHeading("fas fa-scale-unbalanced color-2")
    budget.createP("Aby móc zainwestować musisz dokladnie wiedzieć na co wydajesz swoje pieniądze. Dodaj swoje srodki i wydatki w ten sposób obliczysz ile pieniedzy możesz zainwestować.", "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")
    budget.createDoubleContainer("form")

    createBudgetForms()

    const sumary = new BugetSumary()
    sumary.createSumary()

    const bm = new BudgetManager()
    bm.initIncomes()
    bm.initOutgoings()
    bm.countItems()
    bm.countBalance()
    bm.submitIncome()
    bm.submitOutgoing()

    document.getElementById("input-name-incomes").setAttribute('required', true);
    document.getElementById("input-value-incomes").setAttribute('required', true);

    budget.createP("Jeśli uważasz, że Twój budżet jest zakończony klikni przycisk zatwierdz bugdet to Przejdź do zakładki \"Planowanie wydatków\". Pozostała kwota zostanie przekazana do oszczedności. Aby zaplanować budżet na nowo, kliknij \"Wyczyść dane\".", "alert alert-success m-0", "mainArea")

    formBudget.createButton("btn-accept", "btn bg-color-6 text-light tx-uppercase bg-gradient mt-4 mb-4 me-4", "Zatwierdz budżet", "submit", "mainArea")


    formBudget.createButton("clearData", "btn btn-dark text-uppercase text-light bg-gradient mt-4 mb-4", "Wyczyść dane", "submit", "mainArea")

    document.getElementById("btn-accept").addEventListener("click", function (event) {
        bm.aceptBudget()
    })

    const clearDataBtn = document.getElementById("clearData")

    clearDataBtn.addEventListener("click", e => {
        bm.clearBudget()
        bm.initIncomes()
        bm.initOutgoings()
    })


}




