import BasicComponents from "../../components/basic-components";

export default class BugetSumary {

    constructor() {
        this.budget = new BasicComponents()
    }

    createSumary(){
        this.budget.createAndAppendDiv("summary", "summary conainer", "mainArea")
        this.budget.createH2("Podsumowanie Budżetu", "summary", "summary-title mt-4")

        this.budget.createAndAppendDiv("row-summary", "row", "summary")

        this.budget.createAndAppendDiv("col-summary-left", "col-md-4  text-center", "row-summary")
        this.budget.createP("Przychody razem", " p-2 bg-color-2 text-light rounded fw-bold", "col-summary-left")
        this.budget.createP("0", "sum-in color-2", "col-summary-left")

        this.budget.createAndAppendDiv("col-summary-center", "col-md-4 text-center", "row-summary")
        this.budget.createP("Pozostała kwota", "bil p-2 bg-color-6 text-light rounded bold fw-bold", "col-summary-center")
        this.budget.createP("0", "balance color-6", "col-summary-center")

        this.budget.createAndAppendDiv("col-summary-right", "col-md-4 text-center", "row-summary")
        this.budget.createP("Wydatki razem: ", "p-2 bg-color-7 text-light rounded fw-bold", "col-summary-right")
        this.budget.createP("0", "sum-out color-7", "col-summary-right")
    }
}
