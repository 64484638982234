import BasicComponents from "../../components/basic-components";
import Form from "../../components/form";
import GrossNetService from "../../../services/gross-net-service";
import {getElement} from "bootstrap/js/src/util";
import {fetchTableData} from "../../../api/get-data";
import CurrCalculatorService from "../../../services/curr-calculator-service";


export const initCurrCalculator = async () => {

    const res = await fetchTableData("A");

    const options = res.data[0].rates.map(e => e = e.currency)

    const currMap = new Map

    res.data[0].rates.forEach(e => {
        currMap.set(e.currency, e.mid);
    })

    console.log(currMap);

    const currCalcUi = new BasicComponents()
    const formCurrCalc = new Form()

    currCalcUi.createH1("Przelicznik Walut", "mainArea")
    currCalcUi.addIconToHeading("fas fa-calculator color-3")

    currCalcUi.createP("Przelicznk walut korzyta z aktulancyh kursów NBP", "alert m-0 bg-color-3 color-5", "mainArea")

    currCalcUi.createDiv("mainCurrCalc", "row g-3")

    currCalcUi.createAndAppendDiv("bruttoNetto", "col-md-6", "mainCurrCalc")
    currCalcUi.createAndAppendDiv("results-curr", "col-md-6", "mainCurrCalc")

    currCalcUi.createAndAppendDiv("form-wrapper", "shadow p-4 rounded mt-4 mb-4 col", "bruttoNetto")
    currCalcUi.createAndAppendDiv("re", "shadow p-4 rounded mt-4 mb-4 col bg-color-1 text-light", "results-curr")

    formCurrCalc.createForm("formCurrCalc", "form-wrapper")
    currCalcUi.createH2("Wypełnij poniższy formularz", "formCurrCalc", "mt-0 pt-0 color-1")
    formCurrCalc.createLabel("Wpisz kwotę która chcesz obliczyć", "curr-label", "formCurrCalc", "form-amount-input", "form-label mt-4")
    formCurrCalc.createNumberInput("form-amount-input", "form-control mb-4", "formCurrCalc")
    document.getElementById("form-amount-input").setAttribute('required', true);
    currCalcUi.createP("Wybierz sposób obliczenia", "text m-0", "formCurrCalc")

    formCurrCalc.createRadioButton("gross-to-net", "radio", "złotówki na inna walutę", "złotówki na inna walutę", "formCurrCalc")
    formCurrCalc.createRadioButton("net-to-gross", "radio", "inna waluta na złotówki", "inna waluta na złotówki", "formCurrCalc")

    document.getElementById("gross-to-net").checked = true
    formCurrCalc.createLabel("Wybierz walute", "curr-label", "formCurrCalc", "curr-select", "form-label mt-4")

    formCurrCalc.createSelect("curr-select", " form-control mb-4", "formCurrCalc", options)
    formCurrCalc.createButton("form-form-wrapper-submit", "button btn bg-color-3 w-100 text-light", "Zatwierdź", "submit", "formCurrCalc")

    currCalcUi.createPWithId("Wybrana opcja: ", "check-options", "mb-4 p-3 shadow rounded text-light bg-color-4", "re")
    currCalcUi.createSpan(" ", "color-1", "output", "check-options")
    currCalcUi.createPWithId("Kwota do obliczenia: ", "amout-to-count", "mb-4 p-3 shadow rounded bg-color-4", "re")
    currCalcUi.createSpan(" ", "color-1", "display-amount", "amout-to-count")
    currCalcUi.createPWithId("Wybrana waluta: ", "curr-percent", "mb-4 p-3 shadow rounded bg-color-4", "re")
    currCalcUi.createSpan(" ", "color-1", "display-curr-percent", "curr-percent")
    currCalcUi.createPWithId("Cena waluty: ", "value-wrap-curr", "mb-4 p-3 shadow rounded bg-color-5 color-1", "re")
    currCalcUi.createSpan(" ", "color-1", "value-curr", "value-wrap-curr")
    currCalcUi.createPWithId("Obliczona kwota: ", "main-value-wrap", "mb-0 p-3 shadow rounded bg-color-5 color-1 fw-bold", "re")
    currCalcUi.createSpan(" ", "color-success", "main-value", "main-value-wrap")

    const form = document.getElementById("formCurrCalc")
    const amount = document.getElementById("form-amount-input")
    const currency = document.getElementById("curr-select")

    const currCalcService = new CurrCalculatorService

    form.addEventListener("submit", (e) => {
        e.preventDefault()

        document.getElementById("display-amount").textContent = amount.value
        document.getElementById("display-curr-percent").textContent = currency.value

        const option = formCurrCalc.getRadioValue()
        const currDisplay = document.getElementById("value-curr")
        const mainValue = document.getElementById("main-value")

        const valueOfCurr = currMap.get(currency.value)

        if (option === "złotówki na inna walutę") {
            currDisplay.textContent = valueOfCurr
            mainValue.textContent = currCalcService.plnToForeign(amount.value, valueOfCurr)
        } else {
            currDisplay.textContent = valueOfCurr
            mainValue.textContent = currCalcService.foreignToPln(amount.value, valueOfCurr)
        }
    })

}
