import StorageService from "./storage-service";

export class BudgetService {

    static INCOMES = "budget-incomes"
    static OUTGOINGS = "budget-outgoings"

    constructor() {
        this.incomesStorage = new StorageService(BudgetService.INCOMES)
        this.outgoingsStorage = new StorageService(BudgetService.OUTGOINGS)

    }

    getBudgetIncomes(){
        return this.incomesStorage.findAll()
    }

    getBudgetOutgoings(){
        return this.outgoingsStorage.findAll()
    }

    addIncome(item) {
        this.incomesStorage.save(item)
    }

    addOutgoing(item) {
        this.outgoingsStorage.save(item)
    }

    removeIncome(id) {
        this.incomesStorage.remove(id)
    }

    removeOutgoing(id) {
        this.outgoingsStorage.remove(id)
    }


    sumIncomes(){
        return this.#sumItems(this.getBudgetIncomes())
    }

    sumOutgoings(){
        return this.#sumItems(this.getBudgetOutgoings())
    }

    #sumItems(items){
        return items.reduce((acc, curr) => {
            acc += curr.value
            return acc
        }, 0 )
    }

    getBalace(){
        return (this.sumIncomes() - this.sumOutgoings()).toFixed(2)
    }

    clearData(){
        localStorage.setItem("budget-incomes", "[]")
        localStorage.setItem("budget-outgoings", "[]")
    }

}


