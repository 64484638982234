import date from 'date-and-time';

export default class Time {

    #clock() {
        const time = new Date();
        const godzina = time.getHours();
        const min = time.getMinutes();
        const sek = time.getSeconds();
        document.getElementById("clock").innerHTML = "" + godzina +
            ((min < 10) ? ":0" : ":") + min +
            ((sek < 10) ? ":0" : ":") + sek;
    }

    #createDate() {
        const now = new Date()
        //document.getElementById("day").innerHTML = date.format(now, 'dddd');
        document.getElementById("date").innerHTML = date.format(now, 'DD/MM/YYYY  ');
    }

    generateTime() {
        setInterval(this.#clock, 1000);
        this.#createDate()
    }

}







