import BasicComponents from "../../components/basic-components";
import Form from "../../components/form";
import PlaningService from "../../../services/planing-service";
import {getElement} from "bootstrap/js/src/util";
import {fetchMetalRates, fetchNewApi} from "../../../api/get-data";
import date from "date-and-time";
import StorageService from "../../../services/storage-service";

export const initPlaning = async () => {

    // ------------------------------------ header ----------------------------------
    const planing = new BasicComponents()
    planing.createH1("Planowanie", "mainArea")
    planing.addIconToHeading("fas fa-money-bill-trend-up color-3")
    planing.createP("Zaplanuj ile chcesz wydac na inwestycje", "alert alert-success m-0", "mainArea")
    // -----------------------------------------------------------------------------

    // ----------------------------- kwoty do wyswietlnia ----------------------------
    planing.createAndAppendDiv('ivest-row', 'row mt-4', "mainArea")

    const createCard = (divId, text, AmountId) => {
        planing.createAndAppendDiv(divId, 'col-md-4', "ivest-row")
        planing.createAndAppendDiv(divId + '-wrap', 'card shadow text-center', divId)
        planing.createH2(text, divId + '-wrap', "mt-4")
        planing.createAndAppendDiv(AmountId, "mb-3", divId + '-wrap')
    }

    createCard("card-one", "Kwota", "startAmont")
    createCard("card-two", "Zainwestowana kwota", "investedAmout")
    createCard("card-three", "Kwota pozostała", "profit")

    document.getElementById("startAmont").innerText = localStorage.getItem("saves") || "0"

    const walletForm = new Form()
    // ----------------------------- kooniec kwot ----------------------------

    // ----------------------------- formularz ----------------------------
    walletForm.createFormDiv("wallet-container", "container-fluid planing p-4 mt-4 bg-light shadow mb-4 rounded bg-color-3", "mainArea")
    walletForm.createForm("wallet-form", "wallet-container")
    walletForm.createLabel("Tutaj podaj jaka kwotę chcesz zainwestować", "labPodaj", "wallet-form")
    walletForm.createNumberInput("choose", "input form-control mb-4", "wallet-form")
    walletForm.createLabel("Tutaj wybierz opcje w co chesz zainwestowac", "labPodaj", "wallet-form")

    const metalsStorage = new StorageService("metals")

    const today = new Date();
    console.log(date.format(today, "YYYY-MM-DD"))

    if(localStorage.getItem("lastFetchDate") !== date.format(today, "YYYY-MM-DD") || !localStorage.getItem("metals")){

        const res = await fetchMetalRates()

        const getMetal = (key, metalPL) => {
            metalsStorage.saveWithId(metalPL, res.metals[key])
        }

        getMetal("aluminum", "aluminium");
        getMetal("copper", "miedź");
        getMetal("gold", "złoto");
        getMetal("lead", "ołów");
        getMetal("nickel", "nikiel");
        getMetal("palladium", "palad");
        getMetal("platinum", "platyna");
        getMetal("silver", "srebro");
        getMetal("zinc", "cynk");
    }

    walletForm.createSelect("investSelect", "form-control mb-4", "wallet-form", metalsStorage.getAllKeys());

    walletForm.createFormDiv("metal-price", "price mb-4 bg-color-2 p-4 color-5", "wallet-form");

    document.getElementById("metal-price").textContent = `Cena za gram wynosi: ${metalsStorage.findOne("aluminium")}`

    document.getElementById("investSelect").addEventListener("change", function() {
        document.getElementById("metal-price").textContent = `Cena za gram wynosi: ${metalsStorage.findOne(this.value)}`
    });

    walletForm.createButton("submit-invest", "btn btn-secondary mb-4", "Zatwierdź", "submit", "wallet-form")

    //----------------------------- koniec formularza -----------------------

    const inputElement = document.getElementById("choose")

    inputElement.setAttribute('required', true);
    inputElement.setAttribute('step', '0.01');

    //----------------------------- kontener na invetycje -----------------------
    planing.createAndAppendDiv("investContainer", "", "mainArea")
    planing.createH2("Twoje inwestycje", "investContainer", "mt-4")


    //----------------------------- Archiwum iwestycji -----------------------
    planing.createAndAppendDiv("investContainer", "", "mainArea")
    planing.createH2("Archiwum investycji", "investContainer", "mt-4")

    //------------------------------------------------------------------------------

    const plan = new PlaningService()

    //---------------------------- Pobieranie Kwot ----------------------------------
    const startAmount = document.getElementById("startAmont")
    const investedAmout = document.getElementById("investedAmout")
    const profit = document.getElementById("profit")
    const initAmount = () => {
        startAmount.innerText = (plan.getCurrentSaves() || 0).toString()
        profit.innerText = (plan.getCurrentSaves() - plan.countInvestments()).toString()
        investedAmout.innerText = plan.countInvestments()
    }

    //initAmount()
    const form = document.getElementById("wallet-form")
    const dok = document.getElementById("investContainer")

    const deleteDivIvestent = (investment) => {
        plan.deleteInvestment(investment)
        //initAmount()
        dok.innerHTML = ""
        initInvestments()
    }

    // --------------------------- Funkcja tworząca nową inwestycję -----------------
    const createInvestment = (investment) => {
        console.log(investment);

        const div = document.createElement("div")

        div.innerText = `Nazwa: ${investment.name} | Wartość investycji: ${investment.amount} | Cena:${investment.metalPrice} | Waga w gramach: ${investment.quantity} | Data: ${investment.date}`
        div.className = "single-outgoing shadow mb-4 p-2 rounded"
        div.id = investment
        document.getElementById("investContainer").appendChild(div)
        const button = document.createElement("button")
        button.innerText = "sprzedaj"
        button.className = "button btn bg-color-2 text-light m-2"
        button.onclick = () => {
            deleteDivIvestent(investment)
        }
        div.appendChild(button)
    }

    const initInvestments = () => {
        if (plan.getAllInvestments()) {
            plan.getAllInvestments()
                .forEach((investment) => {
                    createInvestment(investment)
                })
        }
    }


    // --------------------------- Funkcja inicjujaca inwestycje -----------------

    initInvestments()

    form.addEventListener("submit", (e) => {
        e.preventDefault()

        const value = document.getElementById("choose").value
        const option = document.getElementById("investSelect").value

        plan.addInvestment(value, option)
        console.log("to działa")
        dok.innerHTML = ""
        initInvestments()
        // investedAmout.innerText = plan.countInvestments()
        // profit.innerText = (plan.getCurrentSaves() - plan.countInvestments()).toString()

    })

    // walletForm.createButton("getData", "test", "zzz", "", "mainArea")

}