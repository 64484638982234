//import $ from 'jquery';
//import DataTable from 'datatables.net-dt';
import {fetchTableData} from "../../../api/get-data";
import {DataTable} from "simple-datatables";


export const fillData = async () => {

    const res = await fetchTableData("A");

    const arr = res.data[0].rates.reduce((acc, cur, i) => {//console.log(cur)
        acc.push([i+1 ,cur.code, cur.currency,  cur.mid]);
        return acc;
    }, [])

    //console.log(arr);

    const datatable = new DataTable("#ex", {
        searchable: true,
        fixedHeight: true,
        data: {
            headings: ["id", "kod", "waluta", "kurs"],
            // data: [["a", "x", "z"], ["b", "w", "q"], ["c", "s", "w"]]
            data: arr
        },
        labels: {
            searchTitle: "test",
            placeholder: "Wyszukaj",
            perPage: "wyników na stronę",
            noResults: "Brak wyników wyszukiwania",
            info: `Wyświetlanie od {start} do {end} z {rows} wpisów`
        }
    },)
}




