import axios from 'axios'; // Importujemy axios

export default class GoldPriceService {
    constructor() {
        this.dateMap = new Map();
        this.apiUrl = 'https://api.nbp.pl/api/cenyzlota';
    }

    // Inicjalizacja mapy dat z roku wstecz
    fillPastYear(fromDate) {
        const startDate = new Date(fromDate);
        startDate.setFullYear(startDate.getFullYear() - 1);

        while (startDate <= new Date(fromDate)) {
            const year = startDate.getFullYear();
            const month = String(startDate.getMonth() + 1).padStart(2, '0');
            const day = String(startDate.getDate()).padStart(2, '0');
            const date = `${year}-${month}-${day}`;
            this.dateMap.set(date, null); // Inicjalizujemy z wartością null
            startDate.setDate(startDate.getDate() + 1);
        }
    }

    // Pobieranie cen złota dla zakresu dat
    async fetchGoldPrices(startDate, endDate) {
        try {
            const response = await axios.get(`${this.apiUrl}/${startDate}/${endDate}/?format=json`);
            const data = response.data;

            // Uzupełnianie mapy cenami z odpowiednich dat
            data.forEach(entry => {
                const date = entry.data;
                const price = entry.cena;
                this.dateMap.set(date, price);
            });

            // Zapisanie mapy do localStorage
            this.saveMapToLocalStorage();
            console.log("Zaktualizowana mapa:", this.dateMap);
        } catch (error) {
            console.error("Błąd podczas pobierania danych:", error.message || error);
        }
    }

    // Funkcja do zapisywania mapy do localStorage
    saveMapToLocalStorage() {
        const jsonString = JSON.stringify(Array.from(this.dateMap.entries()));
        localStorage.setItem('goldPrices', jsonString);
        localStorage.setItem('lastFetchDate', new Date().toISOString().split('T')[0]); // Zapiszemy datę ostatniego pobrania
    }

    // Funkcja do wczytywania mapy z localStorage
    loadMapFromLocalStorage() {
        const jsonString = localStorage.getItem('goldPrices');
        if (jsonString) {
            const entries = JSON.parse(jsonString);
            this.dateMap = new Map(entries);
        } else {
            this.dateMap = new Map();
        }
        return this.dateMap;
    }

    // Funkcja do sprawdzenia, czy trzeba pobrać nowe dane
    async fetchGoldPricesIfNeeded() {
        const lastFetchDate = localStorage.getItem('lastFetchDate');
        const todayDate = new Date().toISOString().split('T')[0];

        if (lastFetchDate === todayDate) {
            console.log("Dane są już aktualne, nie pobieramy ponownie.");
        } else {
            const startDate = lastFetchDate || new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
            await this.fetchGoldPrices(startDate, todayDate);
        }
    }
}

