export default class Form{
    #mainArea

    constructor() {
        this.#mainArea = document.getElementById('mainArea');
    }

    createForm(id, parentId, classForm = "form") {
        const parent = document.getElementById(parentId);
        const form = document.createElement("form");
        form.id = id;
        form.className = classForm;
        parent.append(form);
    }

    createFormDiv(id, divClass, parentId){
        const parent = document.getElementById(parentId);
        const div = document.createElement("div");
        div.id = id;
        div.className = divClass;
        parent.append(div);
    }

    createLabel(text, id, parentId, forElement, className="form-label"){
        const parent = document.getElementById(parentId);
        const label = document.createElement("label");
        label.id = id;
        label.className = className;
        label.innerHTML = text;
        label.for = forElement;
        parent.append(label);
    }

    createInput(id, type, classInput, parentId, placeholder) {
        const input = document.createElement("input");
        const parent = document.getElementById(parentId);
        input.id = id;
        input.type = type;
        input.className = classInput;
        input.placeholder = placeholder;
        parent.appendChild(input);
    }

    createNumberInput(id, classInput, parentId) {
        const input = document.createElement("input");
        const parent = document.getElementById(parentId);
        input.id = id;
        input.type = "number";
        input.className = classInput;
        input.min = '0'
        input.step = "0.01"
        input.name="amount"
        input.setAttribute("reqired", "true")
        parent.appendChild(input);
    }

    createSelect(id, classInput, parentId, options) {
        const select = document.createElement("select");
        const parent = document.getElementById(parentId);
        select.id = id;
        select.className = classInput;

        options.forEach(optionText => {
            const option = document.createElement("option");
            option.value = optionText;
            option.textContent = optionText;
            select.appendChild(option);
        });

        parent.appendChild(select);
    }

    createRadioButton(id, name, value, labelText, parentId) {
        // Tworzenie elementów
        const parent = document.getElementById(parentId);
        const label = document.createElement("label");
        const radio = document.createElement("input");
        const breakLine = document.createElement("br");

        // Konfiguracja elementu input typu radio
        radio.type = "radio";
        radio.id = id;
        radio.name = name;
        radio.value = value;

        // Dodawanie radia do labela i tekstu
        label.appendChild(radio);
        label.appendChild(document.createTextNode(` ${labelText}`));

        // Dodanie etykiety i przerwy do rodzica
        parent.appendChild(label);
        parent.appendChild(breakLine);
    }

    createButton( id, classButton, text, type, parentId ) {
        const button = document.createElement("button");
        const parent = document.getElementById(parentId);
        button.id = id;
        button.innerHTML = text;
        button.className = classButton;
        button.type = type;
        parent.append(button);
    }

    getRadioValue() {
        // Pobierz wszystkie elementy typu radio z formularza
        const radios = document.querySelectorAll('input[type="radio"]');

        let selectedValue = null;

        // Iteruj przez radio buttony i sprawdź, który jest zaznaczony
        radios.forEach((radio) => {
            if (radio.checked) {
                selectedValue = radio.value;
            }
        });

        // Wyświetl wynik
        document.getElementById('output').textContent = selectedValue ? `${selectedValue}` : 'Nie wybrano żadnej opcji.';

        return selectedValue
    }


}