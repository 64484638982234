import Form from "../../components/form";
import BasicComponents from "../../components/basic-components";

const budget = new BasicComponents()
const budgetForms = new Form()

export const createBudgetForms = () =>{
    budgetForms.createForm('form-incomes', "form-left")
    budgetForms.createFormDiv("left-form-incomes-top", "left-top bg-color-2 shadow text-white text-center p-3", "form-incomes")
    budget.createH2("Przychody", "left-form-incomes-top", "mb-0")
    budgetForms.createFormDiv("left-form-incomes", "left-container card p-3 bg-light shadow", "form-incomes")
    budgetForms.createLabel('Podaj nazwę dochodu', "label-for-income", "left-form-incomes", "input-name-incomes")
    budgetForms.createInput("input-name-incomes", "text", "form-control mb-4", "left-form-incomes", "Praca")
    budgetForms.createLabel('Podaj kwotę dochodu', "label-for-income", "left-form-incomes", "input-value-incomes")
    budgetForms.createNumberInput("input-value-incomes", "form-control mb-4", "left-form-incomes")
    budgetForms.createButton("buttIncome", "btn bg-color-2  text-light bg-gradient shadow ", "Dodaj przychód +", "submit", "left-form-incomes")

    budgetForms.createFormDiv("incomes-list", "left-container", "form-incomes")

    budgetForms.createForm('form-outgoings', "form-right")
    budgetForms.createFormDiv("right-form-outgoings-top", "right-top bg-color-7 shadow text-white text-center p-3", "form-outgoings")
    budget.createH2("Wydatki", "right-form-outgoings-top", "mb-0")
    budgetForms.createFormDiv("right-form-outgoings", "right-container card p-3 bg-light shadow", "form-outgoings")
    budgetForms.createLabel('Podaj nazwę Wydatku', "label-for-out-name", "right-form-outgoings", "input-name-outgoins")
    budgetForms.createInput("input-name-outgoings", "text", "form-control mb-4", "right-form-outgoings", "Benzyna")
    budgetForms.createLabel('Podaj kwote Wydatku', "label-for-uot-value", "right-form-outgoings", "input-value-outgoins")
    budgetForms.createNumberInput("input-value-outgoings", "form-control mb-4", "right-form-outgoings")
    budgetForms.createButton("buttOutgoig", "btn bg-color-7 text-light tx-uppercase bg-gradient ", "Dodaj wydatek -", "submit", "right-form-outgoings")

    budgetForms.createFormDiv("outgoings-list", "right-container", "form-outgoings")
}
