import BasicComponents from "../../components/basic-components";
import {createCurrentGoldPrice} from "../../components/current-gold";
import Form from "../../components/form";
import {fetchGoldData, fetchGoldDataFromDate, fetchGoldRage} from "../../../api/get-data";
import ApexCharts from "apexcharts";
import date from "date-and-time";

export const initGold = async () => {
    const gold = new BasicComponents()

    gold.createH1("Złoto", "mainArea")
    gold.addIconToHeading("fa-solid fa-coins color-2")

    gold.createP(`Sprawdź ile w ostatnim czasie wrosła cena złota, dane udostepnione prze NBP `, "alert alert-success m-0 mb-4 alert-dismissible fade show", "mainArea")

    await createCurrentGoldPrice().catch((err) => console.log(err));

    gold.createCardChart("mainArea", "Złoto ostatnie 30 dni", "goldChart", "bar-chart")


    const formGold = new Form()
    gold.createDiv("gold-buttons", "row")
   //gold.createAndAppendDiv("10-years-wraper", "col-md-2", "gold-buttons")
   // gold.createAndAppendDiv("5-years-wraper", "col-md-2", "gold-buttons")
    gold.createAndAppendDiv("1-year-wraper", "col-md-3", "gold-buttons")
    gold.createAndAppendDiv("6-months-wraper", "col-md-3", "gold-buttons")
    gold.createAndAppendDiv("3-months-wraper", "col-md-3", "gold-buttons")
    gold.createAndAppendDiv("1-month-wraper", "col-md-3", "gold-buttons")

   // formGold.createButton("10-years-btn", "btn button bg-color-1 w-100 color-5  mb-4 ", "10 lat", "", "10-years-wraper")
  //  formGold.createButton("5-years-btn", "btn button bg-color-1 w-100 color-5  mb-4 ", "5 lat", "", "5-years-wraper")
    formGold.createButton("1-year-btn", "btn button bg-color-1 w-100 color-5  mb-4 ", "1 rok", "", "1-year-wraper")
    formGold.createButton("6-month-btn", "btn button bg-color-1 w-100 color-5  mb-4 ", "6 mięsięcy", "", "6-months-wraper")
    formGold.createButton("3-month-btn", "btn button bg-color-1 w-100 color-5 mb-4 ", "3 miesiące", "", "3-months-wraper")
    formGold.createButton("1-month-btn", "btn button bg-color-1 w-100 color-5 mb-4 ", "1 miesiąc", "", "1-month-wraper")

    gold.createAndAppendDiv("formDateGold", "row", "mainArea")

    // formGold.createForm("goldForm" , "formDateGold" , "form col-md-6")
    // formGold.createLabel("Podaj zakres - domysnie ostanie 30 dni", "goldLabel", "goldForm", "label", "mb-4")
    // //formGold.createInput("range", "number", "form-control", 'goldForm')
    // formGold.createNumberInput("range", 'form-control', "goldForm")
    // formGold.createButton("goldButton", "btn button bg-color-2 color-5 mt-4 mb-4", "Zatwierdź", "submit", "goldForm")

    formGold.createForm("gold-form-date" , "mainArea" , "form row shadow rounded p-4 m-0 mb-4 color-1 bg-color-5")
    gold.createH2("Podaj zakres dat", "gold-form-date", "col-md-12 mb-4")
    gold.createAndAppendDiv("left-form-gold", "col-md-6", "gold-form-date")
    formGold.createLabel("Podaj zakres - Od", "goldLabelFrom", "left-form-gold", "label", "")
    formGold.createInput("startDate", "date", "form-control", 'left-form-gold')
    gold.createAndAppendDiv("right-form-gold", "col-md-6", "gold-form-date")
    formGold.createLabel("Podaj zakres - Do", "goldLabelTo", "right-form-gold", "label", "")
    formGold.createInput("endDate", "date", "form-control", 'right-form-gold')
    //formGold.createNumberInput("range", 'form-control', "gold-form-date")
    gold.createAndAppendDiv("accept-form-gold", "col-md-12", "gold-form-date")
    formGold.createButton("goldButton", "btn button bg-color-2 color-5 mt-4", "Zatwierdź", "submit", "accept-form-gold")


    const showData = async (last) => {
        //console.log(await fetchGoldData(30))

        const goldData = await fetchGoldData(last)

        console.log(goldData.data)

        const dates = goldData.data.map(d => d.data)
        const Goldrates = goldData.data.map(c => c.cena )

        const chartSeries = [
            {
                name: `Gold`,
                data: Goldrates
            }
        ]

        const options = {
            series: chartSeries,
            chart: {
                height: 350,
                type: 'line'
            },
            dataLabels: {
                enabled: false
            },

            xaxis: {
                type: 'datetime',
                categories: dates
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            },
            colors:['#9DA65D','#202426', '#6C733D', '#BFAE2B']
        };


        const chart = new ApexCharts(document.getElementById("goldChart"), options);

        await chart.render()


        document.getElementById("gold-form-date").addEventListener("submit", async (e) => {
            e.preventDefault()
            console.log("działa")

            const startDate = document.getElementById("startDate").value
            const endDate = document.getElementById("endDate").value

            if (startDate > endDate){
                alert("Błędny zakres dat")
                return
            }

            const goldData = await fetchGoldRage(startDate, endDate)

            if(goldData === null){
                //alert("Brak danych o pdanym zakresie")
                return
            }

            const dates = goldData.data.map(d => d.data)
            const Goldrates = goldData.data.map(c => c.cena)
            
            await chart.updateSeries([
                {
                    name: `Gold`,
                    data: Goldrates
                }
            ])
            
            await chart.updateOptions({
                xaxis: {
                    type: 'datetime',
                    categories: dates
                }
            })
        })


        const getDataAndActual = async (last) => {
            const goldData = await fetchGoldData(last)

            const dates = goldData.data.map(d => d.data)
            const Goldrates = goldData.data.map(c => c.cena)
            
            await chart.updateSeries([
                {
                    name: `Gold`,
                    data: Goldrates
                }
            ])
            
            await chart.updateOptions({
                xaxis: {
                    type: 'datetime',
                    categories: dates
                }
            })
        }
        



        document.getElementById("1-year-btn").addEventListener("click", async e => {
            
            const goldData = await fetchGoldData(250)

            const dates = goldData.data.map(d => d.data)
            const Goldrates = goldData.data.map(c => c.cena)
            
            await chart.updateSeries([
                {
                    name: `Gold`,
                    data: Goldrates
                }
            ])
            
            await chart.updateOptions({
                xaxis: {
                    type: 'datetime',
                    categories: dates
                }
            })
        

            console.log('1')
        })

        document.getElementById("6-month-btn").addEventListener("click", async e => {
            getDataAndActual(120)
        })

        document.getElementById("3-month-btn").addEventListener("click", e => {
            getDataAndActual(60)
            console.log('3 m')
        })

        document.getElementById("1-month-btn").addEventListener("click", async e => {
            getDataAndActual(24)

            console.log('1 m')
        })

    }

    showData(30).catch(error => {
        console.error('Wystąpił błąd!', error);
    })


}






