import date from 'date-and-time';
import StorageService from "./storage-service"

export default class PlaningService {
    constructor() {
        this.saves = localStorage.getItem("saves")
        this.investStorage = new StorageService("investments");
        this.metalsStorage = new StorageService("metals");
    }

    getCurrentSaves() {
        return this.saves
    }

    getAllInvestments() {
        return this.investStorage.findAll();
    }

    addInvestment(amount = 0, name = "",) {

        //const investMap = new Map(Object.entries(JSON.parse(localStorage.getItem("investments") || '{}' )))
        const investDate = new Date()
        //const mapToObject = map => Object.fromEntries(map.entries());

        // const id = JSON.parse(localStorage.getItem("investments")|| "[]").length + 1
        //const id = "invest" + Date.now().toString()

        // key = in + hash date w milisekundach

        // object
        // name = pobierane z selecta
        // data = data zakupu inwestycji
        // kwota inwestycji - ile pienidzy na to zostało przeznaczone
        // cena metalu - ile wynosiła cena złota w okresie inwestycji
        // ilosć kupionego metalu waluty

        //const val = document.getElementById("investSelect").value
        //const metalPrice = localStorage.getItem("metals").

        console.log(this.metalsStorage.findOne(name))

        const value = {
                name,
                date: date.format(investDate, 'DD/MM/YYYY'),
                amount,
                metalPrice: this.metalsStorage.findOne(name),
                quantity: amount * this.metalsStorage.findOne(name)
                //val
        }

        this.investStorage.save(value);
           // const investMap = new Map
            //investMap.set(id, value)
            //localStorage.setItem("investments", JSON.stringify(this.mapToObject(investMap)))

        //console.log(JSON.parse(localStorage.getItem("investments")))

    }

    getSingleInvestment(keyInvestment) {
        console.log(localStorage.getItem(keyInvestment.value()));
        return localStorage.getItem(keyInvestment.value())
    }

    deleteInvestment(investment) {
        console.log(investment);
        console.log(investment.id)
        const del = JSON.parse(localStorage.getItem("investments")).filter(item => item.id !== investment.id)
        console.log(del);
        localStorage.setItem("investments", JSON.stringify(del))
    }

    countInvestments() {
        const arr = this.getAllInvestments()
        return arr.reduce((acc, item) => {
            console.log("acc", item)
            acc += Number.parseFloat(item.investmentAmount);
            return acc
        }, 0)
    }
}
