import {BudgetService} from "../../../services/budget-service";
import BudgetItem from "../../../models/budget-item";

const bs = new BudgetService();

export default class BudgetManager {

    constructor() {
        this.formLeft = document.getElementById("form-left")
        this.incomesList = document.getElementById("incomes-list")
        this.formIncomes = document.getElementById("form-incomes")
        this.outgoingList = document.getElementById("outgoings-list")
        this.formRight = document.getElementById("form-right")
        this.formOutgoing = document.getElementById("form-outgoings")
        this.balance = document.querySelector(".balance")
    }

    createItem(idItem, item, key) {
        const div = document.createElement("div")
        div.innerText = item.name + ": " + item.value
        div.className = "single-item shadow p-2" + key
        div.id = idItem
        const list = "budget-incomes" === key ? document.getElementById("incomes-list") : document.getElementById("outgoings-list");
        list.appendChild(div)
        const button = document.createElement("button")
        button.innerText = "x"
        button.className = "button btn bg-color-2 text-light"
        button.onclick = () => this.deleteItem(idItem, key, list)
        div.appendChild(button)
    }

    deleteItem(idItem, key, list) {
        list.innerHTML = ""

        if(key === "budget-incomes"){
            bs.removeIncome(idItem)
            this.initIncomes()
        }else {
            bs.removeOutgoing(idItem)
            this.initOutgoings()
        }

        this.countBalance()
        this.countItems(key)
    }

    initIncomes() {
        bs.getBudgetIncomes().forEach(item => {
            this.createItem(item.id, item, BudgetService.INCOMES)
        })
    }

    initOutgoings() {
        bs.getBudgetOutgoings().forEach(item => {
            this.createItem(item.id, item, BudgetService.OUTGOINGS)
        })
    }

    countItems() {
        const sumIn = document.querySelector(".sum-in")
        const sumOut = document.querySelector(".sum-out")
        sumIn.innerText = bs.sumIncomes()
        sumOut.innerText = bs.sumOutgoings()

    }

    submitIncome() {
        this.formIncomes.addEventListener("submit", (e) => {
            e.preventDefault();
            const name = document.getElementById("input-name-incomes").value
            const value = Number(document.getElementById("input-value-incomes").value)
            //const income = {name: name.value, value: value.value};
            //bs.addItem(name, value, BudgetService.INCOMES)
            bs.addIncome(new BudgetItem(name, value))
            this.incomesList.innerHTML = ""
            this.initIncomes()
            this.countItems()
            this.countBalance()
        })
    }

    submitOutgoing() {
        this.formOutgoing.addEventListener("submit", (e) => {
            e.preventDefault();
            const name = document.getElementById("input-name-outgoings").value
            const value = Number(document.getElementById("input-value-outgoings").value)
            //const income = {name: name.value, value: value.value};
            bs.addOutgoing(new BudgetItem(name, value))
            this.outgoingList.innerHTML = ""
            this.initOutgoings()
            this.countItems()
            this.countBalance()
        })
    }

    countBalance() {
        this.balance.innerText = bs.getBalace()
        return bs.getBalace()
    }

    aceptBudget() {
        localStorage.setItem("saves", this.countBalance().toString())
    }

    clearBudget() {
        bs.clearData()
    }


}

