import {fetchTableData, fetchTableDataLast} from "../../api/get-data";

export const fillCurrencyData = async () => {

    const res = await fetchTableData("A");
    const resLast = await fetchTableDataLast("A", "2");

    const firstCard = document.getElementById("curr-card-firts")
    const secondCard = document.getElementById("curr-card-seconds")
    const thirdCard = document.getElementById("curr-card-thirds")
    const fourthCard = document.getElementById("curr-card-fourth")

    const funt = (res.data[0].rates[10].mid - resLast.data[0].rates[10].mid).toFixed(2)
    const euro = (res.data[0].rates[7].mid - resLast.data[0].rates[7].mid).toFixed(2)
    const frank = (res.data[0].rates[9].mid - resLast.data[0].rates[9].mid).toFixed(2)
    const dolar = (res.data[0].rates[1].mid - resLast.data[0].rates[1].mid).toFixed(2)
    //
    // const funtIcon = funt > 0 ? ' : funt +' <i class="fa-solid fa-solid fa-arrow-trend-down"></i>'

    const trend = (curr) => {
        if(curr > 0) {
            return curr + ' <i class="fa-solid fa-arrow-trend-up text-success"></i>'
        } else if (curr < 0){
            return curr + ' <i class="fa-solid fa-arrow-trend-down text-danger"></i>'
        }else {
            return curr + ' -'
        }
    }


    firstCard.innerHTML = `<p class="m-0"> <i class="fas fa-sterling-sign"></i> Funt: ${res.data[0].rates[10].mid} <br> ${trend(funt)} </p>`
    secondCard.innerHTML = `<p class="m-0"> <i class="fas fa-euro-sign"></i> Euro: ${res.data[0].rates[7].mid} <br> ${trend(euro)} </p>`
    thirdCard.innerHTML = `<p class="m-0"> <i class="fas fa-franc-sign"></i> Frank: ${res.data[0].rates[9].mid} <br> ${trend(frank)} </p>`
    fourthCard.innerHTML = `<p class="m-0"> <i class="fas fa-dollar-sign"></i> Dolar: ${res.data[0].rates[1].mid} <br> ${trend(dolar)}</p>`

    //secondCard.innerText = `Euro ${res.data[0].rates[7].mid}`
    //thirdCard.innerText = `Frank ${res.data[0].rates[9].mid}`
    //fourthCard.innerText = `Dolar ${res.data[0].rates[1].mid}`

    // this.createP("Funt: ", "text", "curr-card-firts" )
    // this.createP("Euro: ", "text", "curr-card-firts" )
    // this.createP("Frank: ", "text", "curr-card-firts" )
    // this.createP("Dolar: ", "text", "curr-card-firts" )


}