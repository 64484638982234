export default class GrossNetService {

    grossToNet(value, vat) {
        return (value / (1 + vat/100)).toFixed(2)
    }

    netToGross(value, vat) {
        return (value * (1 + vat/100)).toFixed(2)
    }

    countVatNetToGross(value, vat){
        return value * vat/100
    }

    countVatGrossToNet(value, vat){
        return (value - this.grossToNet(value, vat)).toFixed(2)
    }

}