import BasicComponents from "./basic-components";
import {fetchGoldData} from "../../api/get-data";


export const createCurrentGoldPrice = async () => {
    const gold = new BasicComponents();
    const currGoldPrice = await fetchGoldData(2)

    const curr = ((currGoldPrice.data[1].cena).toFixed(2)).toString();
    const before = ((currGoldPrice.data[0].cena).toFixed(2)).toString();
    const status = ((currGoldPrice.data[1].cena).toFixed(2) - (currGoldPrice.data[0].cena).toFixed(2)).toFixed(2)

    //const status = -2
    // console.log("to jesyn log curr", curr)
    // console.log("to jesyn log curr", currGoldPrice)
    gold.createAndAppendDiv("gold-price", "gold-price-wrap row", "mainArea")

    gold.createAndAppendDiv("gold-price-1", "gold-price-wrap  col-xl-4 ", "gold-price")
    gold.createAndAppendDiv("gold-price-2", "gold-price-wrap  col-xl-4 ", "gold-price")
    gold.createAndAppendDiv("gold-price-3", "gold-price-wrap  col-xl-4 ", "gold-price")


    gold.createPWithId("Aktualna cena złota wynosi: \n", "actual-gold", "color-1 text-center bg-color-5 p-4 rounded shadow", "gold-price-1",)
    gold.createSpan(curr + " zł", " fw-bold", "current-gold-price", "actual-gold")

    gold.createPWithId("Poprzednia cena złota wynosi: \n", "before-gold", "color-1 text-center bg-color-5 p-4 rounded shadow", "gold-price-2",)
    gold.createSpan(before + " zł", " fw-bold", "before-gold-price", "before-gold")

    gold.createPWithId("Róznica do dnia poprzedniego \n", "status-gold", "color-1  text-center p-4 rounded shadow bg-color-5", "gold-price-3",)
    gold.createSpan(status + " zł ", "fw-bold", "status-gold-price", "status-gold")

    const statusGoldPrice = document.getElementById("status-gold-price")

    console.log(document.getElementById("gold-price-3"))


    if (status < 0) {
        statusGoldPrice.classList.add("text-danger")
        document.getElementById("status-gold-price")
        const icon = document.createElement("i")
        icon.className = "fas fa-arrow-trend-down"
        statusGoldPrice.append(icon)
    }

    if (status > 0) {
        statusGoldPrice.classList.add("text-success")
        document.getElementById("status-gold-price")
        const icon = document.createElement("i")
        icon.className = "fas fa-arrow-trend-up"
        statusGoldPrice.append(icon)
    }

}

