import {getElement} from "bootstrap/js/src/util";
import {createElement} from "simple-datatables";

export default class BasicComponents {
    #mainArea

    constructor() {
        this.#mainArea = document.getElementById('mainArea');
        // this.#currentValue = currentValue;
        // this.#beforeValue = beforeValue;
        // this.#no = no;
        // this.#date = date;
    }

    createH1(title = "Title", parentId) {
        const h1 = document.createElement("h1");
        const parentElement = document.getElementById(parentId)
        h1.innerText = title;
        h1.className = "header mt-4";
        parentElement.appendChild(h1)
    }

    createH2(title = "Title", parentId, classH2) {
        const h2 = document.createElement("h2");
        const parentElement = document.getElementById(parentId)
        h2.innerText = title;
        h2.className = classH2;
        parentElement.appendChild(h2)
    }

    createP(text, classP, parentId) {
        const parentElement = document.getElementById(parentId)
        const paragraphElement = document.createElement("p");
        paragraphElement.innerText = text;
        paragraphElement.className = classP;
        parentElement.append(paragraphElement);
    }

    createPWithId(text, id, classP, parentId) {
        const parentElement = document.getElementById(parentId)
        const paragraphElement = document.createElement("p");
        paragraphElement.innerText = text;
        paragraphElement.className = classP;
        paragraphElement.id = id
        parentElement.append(paragraphElement);
    }

    createSpan(text, classSpan, id, parentId) {
        const parentElement = document.getElementById(parentId)
        const spanElement = document.createElement("span");
        spanElement.innerText = text;
        spanElement.className = classSpan;
        spanElement.id = id;
        parentElement.append(spanElement);
    }


    createDiv(divId, divClass) {
        const div = document.createElement("div");
        if (divClass) div.className = divClass;
        if (divId) div.id= divId;
        this.#mainArea.appendChild(div);
        return div;
    }

    createAndAppendDiv(divId, divClass, parentId) {
        const parentElement = document.getElementById(parentId)
        const div = document.createElement("div");
        if (divClass) div.className = divClass;
        if (divId) div.id= divId;
        parentElement.appendChild(div);
        return div;
    }


    createDoubleContainer(id) {
        //const parentElement = document.getElementById(parentId)
        const row = this.createDiv(id+"row", "row");
        this.#mainArea.append(row)
        const leftContainer = this.createDiv(id + "-left", "col-xl-6")
        const rightContainer = this.createDiv(id + "-right", "col-xl-6")
        row.append(leftContainer);
        row.append(rightContainer);

    }

    addIconToHeading(className = "fas fa-star") {
        // Tworzenie elementu ikony
        const icon = document.createElement("i");
        icon.className = className; // Ustawienie klasy ikony (zmień na dowolną ikonę)

        // Pobranie nagłówka h1
        const heading = document.querySelector("h1");

        const span = document.createElement("span");
        span.innerText = " "
        // Dodanie ikony przed nagłówkiem
        heading.prepend(span);
        heading.prepend(icon);

    }


    createCardChart(element, header = "Title", id, iconName, parentId) {
        //const parentElement = document.getElementById(parentId);
        const container = document.getElementById(element)
        const card = this.createDiv("card-" + id, "card mb-4");
        container.append(card);
        const cardHeader = this.createDiv("card-header-" + id, "card-header");
        const icon = document.createElement("i");
        icon.className = `fas fa-${iconName} me-1`;
        cardHeader.append(icon);
        cardHeader.append(header);
        card.append(cardHeader);
        const cardBody = this.createDiv(id, "card-body");
        card.append(cardBody);
    }

    createTable(element, id){
        const el = document.getElementById(element);
        const table = document.createElement("table");
        table.id = id
        table.classList.add("table-striped")
        el.append(table);
        return table;
    }

    createActualCurrencyDataContainer() {
        const row = this.createDiv("row-actual-currency", "row");
        this.#mainArea.append(row)
        const firstCol = this.createDiv("curr-firts", "col-xl-3 col-md-6")
        const secondCol = this.createDiv("curr-second", "col-xl-3 col-md-6")
        const thirdCol = this.createDiv("curr-third", "col-xl-3 col-md-6")
        const fourthCol = this.createDiv("curr-fouth", "col-xl-3 col-md-6")

        row.append(firstCol)
        row.append(secondCol)
        row.append(thirdCol)
        row.append(fourthCol)

        const firstCard = this.createDiv("curr-card-firts", "card bg-color-1 text-white mb-4 p-3 text-center")
        const secondCard  = this.createDiv("curr-card-seconds", "card bg-color-2 text-white mb-4 p-3 text-center")
        const thirdCard = this.createDiv("curr-card-thirds", "card bg-color-3 text-white mb-4 p-3 text-center")
        const fourthCard = this.createDiv("curr-card-fourth", "card bg-color-6 text-white mb-4 p-3 text-center")

        firstCol.append(firstCard)
        secondCol.append(secondCard)
        thirdCol.append(thirdCard)
        fourthCol.append(fourthCard)


    }


}